/* App.css */

html,
body,
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
}

html {
}

/* Header */
.header {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid #8888888e;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

header.scrolled {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

.logo img {
  max-width: 100px;
}

.nav {
  max-width: 100%;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav ul li {
  display: flex;
}

.nav ul li a {
  color: Black;
  padding: 1rem 1.5rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  font-weight: bold;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.nav ul li a:hover {
  text-decoration: underline;
}

/* LandingPage */

.landing-page {
  width: 100%;
  padding: 0.5rem;
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page h1 {
  font-size: 42px;
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.landing-page p {
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.reset-btn {
  display: inline-block;
  background-color: white;
  padding: 1rem 3rem;
  margin: 2rem;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

/* BeerList */

.beerlist-overlay {
  position: fixed;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  z-index: 1000;
  overflow: hidden;
}

.beerlist-container {
  background-color: white;
  width: 100%;
  padding: 0;
  text-align: center;
  position: relative;
  overflow: auto;
}

.beerlist-closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.beerlist-beerItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.beer-info-div {
  width: 100%;
}

.beerlist-beerItem img {
  width: 50px;
}

/* BeerSlot */

.beerslot-container {
  border: 1px solid #8888888e;
  padding: 2rem;
  height: 10rem;
  margin-top: 0.5rem;
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center;
  position: relative;
}

.beerslot-container div {
  text-align: center;
}

.beerslot-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15rem;
  gap: 2rem;
}

.beerslot-data span {
  flex: 1;
}

.info-number {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.beerflight {
  width: 100%;
  margin-bottom: 2rem;
}

/* Beer-info */

.beer-info-page {
  width: 100%;
  padding: 0.5rem;
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beer-info-top {
  text-align: center;
  margin: 1rem 0 2rem 0;
}

.beer-info-middle {
  display: flex;
}

.beer-info-middle p {
  margin: 0.5rem;
}

.beer-info-page img {
  width: 100px;
  height: auto;
  object-fit: contain;
}

.beer-info {
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0px 0 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.beer-info-middle-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.beer-info-bottom {
  margin-top: 0.5rem;
}

.beer-info-bottom p {
  margin: 0.5rem;
}
